@import '../styles/variables';
@import '../styles/colours';

.root {
  padding: 64px 16px;
  text-align: center;

  h1 {
    color: var(--deep-accent);
    font-weight: bold;
  }

  p > a {
    color: $blue-600;
    white-space: nowrap;
  }
  @media (min-width: $view-lg) {
    text-align: left;
  }
}

.button-wrapper {
  margin-top: 64px;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $view-lg) {
    flex-direction: row;
  }
}

.lottie {
  max-width: 340px;
  padding: 36px 36px 0 36px;
  min-height: 268px;
  @media (min-width: $view-lg) {
    max-width: 600px;
    padding: 0;
    min-height: 600px;
  }
}
