@import '../scss/colours';
@import '../scss/variables';

$max-width: 1440px;

.footer {
  background: #272c4a;
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &.footer-absolute {
    @media (min-width: $view-md) {
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.container {
  margin: auto;
  max-width: $max-width;
  padding: 12px 16px;
  position: relative;

  @media (min-width: $view-lg) {
    display: flex;
  }
}

.container > * {
  color: var(--white);
  display: block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.55px;
  line-height: inherit;
  margin: 0 0 0 16px;
  position: relative;
  z-index: 2;

  &:first-child {
    margin: 0 auto 0 0;
  }

  @media (min-width: $view-lg) {
    display: block;
  }
}

.container > a {
  box-shadow: inset 0 -2px 0 0 $pink-700;
  color: var(--white);
  display: inline;
  font-weight: 500;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  white-space: nowrap;

  &:first-of-type {
    margin-left: 0;
  }

  &:focus,
  &:hover {
    box-shadow: inset 0 -4px 0 0 $pink-700;
  }
}
