@import '../styles/variables';

$views: (
  'xxs': $view-xxs,
  'xs': $view-xs,
  'sm': $view-sm,
  'md': $view-md,
  'lg': $view-lg,
  'xl': $view-xl,
);

@each $name, $value in $views {
  @media (min-width: $value) {
    .#{$name}-full {
      flex: 0 0 100%;
      width: 100%;
      align-self: center;
    }
    .#{$name}-half {
      flex: 0 0 calc(1 / 2 * 100%);
      width: 50%;
      align-self: center;
    }
    .#{$name}-one-fourth {
      flex: 0 0 calc(1 / 4 * 100%);
      width: 25%;
      align-self: center;
    }
    .#{$name}-three-fourths {
      flex: 0 0 calc(3 / 4 * 100%);
      width: 75%;
      align-self: center;
    }
    .#{$name}-one-third {
      flex: 0 0 calc(1 / 3 * 100%);
      width: 33.33333%;
      align-self: center;
    }
    .#{$name}-two-thirds {
      flex: 0 0 calc(2 / 3 * 100%);
      width: 66.66666%;
      align-self: center;
    }
  }
}
