// ** Colours **
$black-9000: #000;
$black: #2e2e2e;
$white: #fff;

// Aubergines
$aubergine-600: #7a00bc;
$aubergine-700: #6c099b;
$aubergine-800: #4b1167;
$aubergine-850: #4c1169;
$aubergine-900: #2e0741;

// Aubergines gradients
$aubergine-grad-1: linear-gradient(270deg, $aubergine-800 0%, $aubergine-900 100%);
$aubergine-grad-2: linear-gradient(226.89deg, $aubergine-700 0%, $aubergine-800 100%);

// Blues
$blue-200: #1fb2f2;
$blue-600: #0c7ff1;
$blue-700: #244ba8;

// Light blues
$light-blue-100: #f3f9fe;
$light-blue-500: #d1e5f9;

// Greys
$grey-100: #f9f9f9;
$grey-200: #ededed;
$grey-400: #e4e4e4;
$grey-500: #a39b99;
$grey-600: #6a6a6a;

// Oranges
$orange-700: #ffa800;

// Brand
$pink-700: #de026a;

// Greens
$green: #009a4f;

// Reds
$red: #d0021b;

$primary: #2a282f;
$special: #de026a;
$interactive: #0975e0;
$disabled: #d8d8d8;
