@import '../styles/variables';

.root {
  display: flex;
  flex-wrap: wrap;
}

$views: (
  'xxs': $view-xxs,
  'xs': $view-xs,
  'sm': $view-sm,
  'md': $view-md,
  'lg': $view-lg,
  'xl': $view-xl,
);

@each $name, $value in $views {
  @media (min-width: $value) {
    .#{$name}-row {
      flex-direction: row;
    }
    .#{$name}-row-reverse {
      flex-direction: row-reverse;
    }
    .#{$name}-column {
      flex-direction: column;
    }
    .#{$name}-column-reverse {
      flex-direction: column-reverse;
    }
    .#{$name}-flex-start {
      justify-content: flex-start;
    }
    .#{$name}-flex-end {
      justify-content: flex-end;
    }
    .#{$name}-center {
      justify-content: center;
    }
    .#{$name}-space-between {
      justify-content: space-between;
    }
    .#{$name}-space-around {
      justify-content: space-around;
    }
    .#{$name}-space-evenly {
      justify-content: space-evenly;
    }
  }
}
